<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>{{companyname}} : {{companycode}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class="py-2 px-5" justify="space-between">
            <h3>Student Registration Status Report</h3>
            </v-row>
            <v-row class='pl-4'>
                <v-col md="2">
                    <label>Organization  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete :items="institute_list" item-text="name" item-value="id" v-model="institute" @change="getProgramsYear()"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label>Program  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="program_list" item-text="name" item-value="id" v-model="program"></v-autocomplete>
                </v-col>
                <v-col md="2">
                    <label>Year  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete :items="year_list" item-text="name" item-value="id" v-model="year"> </v-autocomplete>
                </v-col>
                <v-col md="4">
                    <label>Status  &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="status_list" v-model="status"></v-autocomplete>
                </v-col>
                <v-col sm="2">
                    <v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
                </v-col>
                <v-col sm="12">
                    <span class="material-icons" style="color:red;">label</span> Not Registered for TPO <br>
                    <span class="material-icons" style="color:blue;">label</span> Not Eligible for Company<br>
                    <span class="material-icons" style="color:indigo;">label</span> Eligible But Not Applied for Company<br>
                    <span class="material-icons" style="color:orange;">label</span> Applied for this company <br>
                    <span class="material-icons" style="color:purple;">label</span> Selected for this Company <br>
                    <span class="material-icons" style="color:green;">label</span> Placement Done 
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="learner_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
             
            <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="statuswisecount.length!=0">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left" style="font-size : 16px;"> Sr. No. </th>
                            <th class="text-left" style="font-size : 16px;"> Status</th>
                            <th class="text-left" style="font-size : 16px;"> Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in statuswisecount" :key="item.srno" :style="{color:item.color}">
                            <td style="font-size : 16px;">{{ item.srno }}</td>
                            <td style="font-size : 16px;">{{ item.status }}</td>
                            <td style="font-size : 16px;">{{ item.count }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2" style="font-size : 16px;"><span style="float:right;font-weight:600;">Total</span></th>
                            <td style="font-size : 16px;">{{ total }}</td>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
            <br>

            <v-row justify="end" class="mr-5">
                <v-btn v-if="learner_list.length != 0"  @click="exportexcelstudent()" color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="learner_list.length != 0 && status=='Eligible But Not Applied for Company'"  @click="apply_student_in_application_round()" color="success" dark class="mb-2">
                        Apply Student (Eligible But Not Applied for Company)
                </v-btn>
            </v-row>
            <v-data-table  id="exceltablestudent"  :headers="headers" :items="learner_list"  class="elevation-1" :search="search" >
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Stduent List</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                        <span style="width: 20px"></span>
                    </v-toolbar>
                </template>
                <template v-slot:item="{ item }">
                    <tr :style="{color:item.color}" >
                        <td>{{ item.srno }}</td>
                        <td>{{ item.registration_number }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.mobile_no }}</td>
                        <td>{{ item.company }}</td>
                        <td>{{ item.organization }}</td>
                        <td>{{ item.program }}</td>
                        <td>{{ item.year }}</td>
                        <td>{{ item.academicyear }}</td>
                        <td>{{ item.status }}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <v-overlay :value="overlay">
           <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        dialogadd:false,
        reg:"",
        com:"",
        id:"",
        snackbar_msg: "",
        color: "",
        snackbar: false,
        learner_list: [],
        year_list: [],
        institute_list: [],
        program_list: [],
        status_list: [],
        statuswisecount:[],
        search: "",
        year: "",
        status: "",
        institute: null,
        program: null,
        comp_off_id : null,
        companyname : "",
        companycode : "",
        total : 0,
        headers: [ 
            {
                text: 'Sr.No.',
                align: 'left',
                sortable: true,
                value: 'srno',
            },
            {
                text: 'PRN/GR. No.',
                align: 'left',
                sortable: true,
                value: 'registration_number',
            },
            {
                text: 'Name',
                align: 'left',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Email',
                align: 'left',
                sortable: true,
                value: 'email',
            },
            {
                text: 'Mobile No.',
                align: 'left',
                sortable: true,
                value: 'mobile_no',
            },
            {
                text: 'Company',
                align: 'left',
                sortable: true,
                value: 'company',
            },
            {
                text: 'Organization',
                align: 'left',
                sortable: true,
                value: 'organization',
            },
            {
                text: 'Program',
                align: 'left',
                sortable: true,
                value: 'program',
            },
            {
                text: 'Year',
                align: 'left',
                sortable: true,
                value: 'year',
            },
            {
                text: 'AY',
                align: 'left',
                sortable: true,
                value: 'academicyear',
            },
            {
                text: 'Status',
                align: 'left',
                sortable: true,
                value: 'status',
            },
        ],
    }),

    mounted() {
        this.comp_off_id = this.$route.params.comp_off_id;
        const data = {
            comp_off_id: this.comp_off_id,
        };
        axios
            .post("/TPO/studentregstatus", data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.companyname = res.data.companyname;
                    this.companycode = res.data.companycode;
                    this.year_list = res.data.year_list;
                    this.institute_list = res.data.institute_list;
                    this.program_list = res.data.program_list;
                    this.status_list = res.data.status_list;
        
                    if(this.institute_list.length > 0){
                        this.institute = res.data.organizationid;
                    }

                    if(this.program_list.length > 1){
                        this.program_list.push({name: 'All', text: '' });
                        this.program="All";
                    }else if(this.program_list.length > 0){
                        this.program= this.program_list[0].id;
                    }

                    if(this.year_list.length > 1){
                        this.year_list.push({name: 'All', text: ''});
                        this.year="All";
                    }else if(this.year_list.length > 0){
                        this.year= this.year_list[0].id;
                    }
                    this.status = this.status_list[0]; 
                } else {
                    console.log("error fetching data!");
                }
            });
    },

    methods: {
        apply_student_in_application_round(){
            this.overlay = true;
            const data = {
                instituteid: this.institute,
                year_id:this.year,
                program_id:this.program,
                status:this.status,
                comp_off_id: this.comp_off_id,
            };
            axios
                .post("TPO/apply_student_in_application_round",data)
                .then((res) => {
                    
                    if (res.data.msg == "200") {
                        this.showSnackbar("green","Success.");
                        this.overlay = false;
                    } else {
                        this.showSnackbar("red", res.data.msg);
                        this.overlay = false;
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },
        exportexcel() {    
            $("#exceltable").table2excel({  
            name: "Worksheet Name",
            filename: "Student_reg_status_Count_REPORT", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        exportexcelstudent() {    
            $("#exceltablestudent").table2excel({  
            name: "Worksheet Name",
            filename: "Student_reg_status_REPORT", //do not include extension
            fileext: ".xls" // file extension
            });        
        },

        fetchReport() { 
            this.learner_list = [];
            this.statuswisecount = [];
            this.overlay = true;
            const data = {
                instituteid: this.institute,
                year_id:this.year,
                program_id:this.program,
                status:this.status,
                comp_off_id: this.comp_off_id,
            };
            axios
                .post("TPO/getStudentRegData",data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.learner_list = res.data.learner_list;
                        this.statuswisecount = res.data.statuswisecount;
                        this.total = res.data.total;
                        if(this.learner_list.length < 1){
                            this.showSnackbar("red", "Data Not Found");
                        }
                    } else {
                        this.showSnackbar("green", res.data.msg1);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },
        
        getProgramsYear(){
            const data = {
                instituteid: this.institute,
                comp_off_id: this.comp_off_id,
            };
            axios
            .post("TPO/getProgramsByCompanyOffering", data)
            .then((res) => {
                if (res.data.msg == "200") {
                    this.year_list = res.data.year_list;
                    this.program_list = res.data.program_list;
                    if(this.program_list.length > 1){
                        this.program_list.push({name: 'All', text: '' });
                        this.program="All";
                    }else if(this.program_list.length > 0){
                        this.program= this.program_list[0].id;
                    }

                    if(this.year_list.length > 1){
                        this.year_list.push({name: 'All', text: ''});
                        this.year="All";
                    }else if(this.year_list.length > 0){
                        this.year= this.year_list[0].id;
                    }
                } else {
                    this.showSnackbar("green", res.data.msg1);
                }
            }).catch((error) => {
            window.console.log(error);
            }).finally(() => {
            });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        
        clear() {
            this.excelfile = null;
            this.exceldialog = false;
        },
        
        closeerrordialog() {
            this.clear();
            this.excelerrordialog = false;
        },
    }
};
</script>
<style scoped>
    .required{
        color:red;
        font-size:14px;
        font-style:bold;
    }

    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
</style>    